import React, { useState } from "react"
import "./ContentWrapper.scss"
import { IContentItem } from "../../type/doc/IContent"
import { useSelector } from "react-redux"
import { contentReducer, contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import StandardButton from "../buttons/StandardButton"
import cn from "classnames"
import { getAllLanguages } from "../../type/TLanguage"
import useReduxDispatch from "../../store/useReduxDispatch"

interface IContentWrapperProps {
    item: IContentItem
    children?: React.ReactNode
    canMoveUp: boolean
    canMoveDown: boolean
    moveUp: () => void
    moveDown: () => void
}

const ContentWrapper = ({ item, children, canMoveDown, canMoveUp, moveDown, moveUp }: IContentWrapperProps) => {
    const currentLanguage = useSelector(contentSelectors.getCurrentEditorLanguage)
    const dispatch = useReduxDispatch()
    const languages = getAllLanguages()
    return (
        <div className="content-wrapper-comp">
            <div className="toolbar">
                <div className="actions">
                    <StandardButton
                        isSmall
                        title={item.mustDelete ? "Undo delete" : "Delete"}
                        onClick={() => {
                            if (item.unsaved) {
                                dispatch(contentReducerActions.removeItem(item.itemId))
                                return
                            }
                            dispatch(
                                contentReducerActions.updateItem({
                                    ...item,
                                    mustDelete: !item.mustDelete,
                                })
                            )
                        }}
                    />
                    {canMoveUp && (
                        <StandardButton
                            isSmall
                            title="Up"
                            onClick={() => {
                                moveUp()
                            }}
                        />
                    )}
                    {canMoveUp && (
                        <StandardButton
                            isSmall
                            title="Down"
                            onClick={() => {
                                moveDown()
                            }}
                        />
                    )}
                </div>
                {!item.mustDelete && (
                    <div className="languages">
                        {languages.map((l) => {
                            return (
                                <div key={l} className={cn("language")}>
                                    <StandardButton
                                        className={cn("language-button")}
                                        inverted={currentLanguage !== l}
                                        isSmall
                                        onClick={() => {
                                            dispatch(contentReducerActions.setCurrentLanguage(l))
                                        }}
                                    >
                                        {l}
                                    </StandardButton>
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
            {!item.mustDelete && <div className="item-content">{children}</div>}
        </div>
    )
}

export default ContentWrapper
