import React from "react"
import "./ApiRouteSubParamsContainer.scss"
import { IExtractedRouteParameter } from "../../services/apiRouteService"
import ApiRouteParam from "./ApiRouteParam"
import cn from "classnames"

interface IApiRouteSubParamsContainerProps {
    params: IExtractedRouteParameter[]
    path: string
    onSubParamsOpenChange: (path: string, isOpen: boolean) => void
    openParams: { [path: string]: boolean }
}

const ApiRouteSubParamsContainer = ({ params, path, openParams, onSubParamsOpenChange }: IApiRouteSubParamsContainerProps) => {
    const isOpen = typeof openParams[path] === "undefined" ? openParams._default : openParams[path]
    return (
        <div className={cn("api-route-sub-params-container", { open: isOpen, closed: !isOpen })}>
            <div
                className="title"
                onClick={() => {
                    console.log(openParams[path], openParams._default)
                    onSubParamsOpenChange(path, !isOpen)
                }}
            >
                <span>{isOpen ? "Hide" : "Show"}</span> <span>child parameters</span>
            </div>
            {isOpen && (
                <div className="params">
                    {params.map((p) => {
                        return (
                            <ApiRouteParam
                                path={path + ":" + p.name}
                                openParams={openParams}
                                onSubParamsOpenChange={onSubParamsOpenChange}
                                key={p.name}
                                param={p}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default ApiRouteSubParamsContainer
