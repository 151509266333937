import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { IUrls } from "../../type/serverConfiguration"
import { configurationReducerActions } from "../reducers/configurationReducer"
import { authService } from "./../../services/authService"
import { ensureUserRelationsThunk, addLastUsedRelationThunk, ensureSwitchUserRelationsFromUrl } from "./userRelationThunks"
import { checkCookieThunk } from "./loginThunks"
import { requestThunk } from "./serverThunks"
import { configurationSelectors } from "./../reducers/configurationReducer"
import { addModalThunk } from "./modalThunks"
import { IUserRelationFlattened } from "../../type/IUserRelation"
import { userRelationReducerActions } from "../reducers/userRelationReducer"
import { endpoints } from "../../data/endpoints"
import { setContentDefaultLanguage } from "./contentThunks"
import { tryToRedirect } from "../../services/redirectService"

export const initializeThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const disableRedirects = (window.location.search ?? "").includes("disableRedirects")
    if (disableRedirects) {
        dispatch(configurationReducerActions.setDisableRedirects(true))
    }
    if (!disableRedirects) {
        if (tryToRedirect(window.location.pathname)) {
            return
        }
    }

    const serverConfiguration = await dispatch(requestThunk<IUrls>(endpoints.Self.getConfiguration))
    serverConfiguration.clientUrl = window.location.origin
    serverConfiguration.origins.Self = window.location.origin
    dispatch(configurationReducerActions.setServerConfiguration(serverConfiguration))

    const authEndpoint = configurationSelectors.endpointConfig(getState(), "Auth")

    authService.setup(serverConfiguration, authEndpoint)

    const authorized = await dispatch(checkCookieThunk())
    authService.setHasApiCookie(authorized.hasApiCookie)
    authService.setIsLoggedIn(authorized.isLoggedIn)

    await dispatch(setContentDefaultLanguage())

    if (authorized.hasApiCookie) {
        authService.setAuthChecked(false)
        await dispatch(ensureUserRelationsThunk())

        const didSwitchUser = await dispatch(ensureSwitchUserRelationsFromUrl())
        dispatch(configurationReducerActions.setInitialized(true))
        if (!didSwitchUser) {
            dispatch(onUserSwitchThunk())
        }
    } else {
        dispatch(configurationReducerActions.setInitialized(true))
    }
}

export const signOutThunk = (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
    const answer = await dispatch(addModalThunk({ type: "logOut" }))
    if (answer.type !== "accepted") {
        return
    }
    const authEndpoint = configurationSelectors.endpointConfig(getState(), "Auth")
    const serverConfiguration = configurationSelectors.serverConfiguration(getState())
    window.location.replace(authEndpoint + "/Account/Logout" + "?=" + serverConfiguration.clientUrl + "/login")
}

export const switchUserThunk =
    (userRelation: IUserRelationFlattened): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        dispatch(userRelationReducerActions.setCurrentRelation(userRelation))
        dispatch(addLastUsedRelationThunk(userRelation))
        dispatch(onUserSwitchThunk())
    }

const onUserSwitchThunk = (): ThunkAction<void, StoreState, null, AnyAction> => async (dispatch, getState) => {
    // Nothing here yet
}
