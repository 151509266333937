export function addMiliseconds(date: Date, miliseconds: number) {
    date = new Date(date.valueOf())
    date.setUTCMilliseconds(date.getUTCMilliseconds() + miliseconds)
    return date
}

export const getFormattedDate = (date: Date, type: TDateFormatType) => {
    const d = new Date(date)

    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d)
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)

    const hh = new Intl.DateTimeFormat("en", { hour: "2-digit", hour12: false }).format(d)
    const mm = ("0" + new Intl.DateTimeFormat("en", { minute: "2-digit" }).format(d).toString()).slice(-2)

    switch (type) {
        case "dots":
            return `${da}.${mo}.${ye} ${hh}:${mm}`
        case "slashes":
            return `${da}/${mo}/${ye} ${hh}:${mm}`
        case "dash-international":
            return `${ye}-${mo}-${da} ${hh}:${mm}`
        case "forfile":
            return `${da}.${mo}.${ye}`
    }
}

export type TDateFormatType = "dots" | "slashes" | "forfile" | "dash-international"
