import React from "react"
import Editor from "react-simple-code-editor"
import Prism, { highlight, languages } from "prismjs"
import "prismjs/components/prism-xml-doc"
import "prismjs/components/prism-json"
import "prismjs/components/prism-javascript"
import "prismjs/components/prism-css"
import "./CodeEditorThemeJsonDefault.scss"
import "./CodeEditor.scss"
import cn from "classnames"

export type TCodeEditorLanguage = "html" | "json" | "css" | "js"

interface ICodeEditorProps {
    code: string
    onChange?: (code: string) => void
    language: TCodeEditorLanguage
    height?: number
    minHeight?: number
    maxHeight?: number
    width?: number
    className?: string
    label?: string
    onSaveKey?: () => void
}

const CodeEditor = ({
    code,
    onChange,
    onSaveKey,
    height,
    width,
    minHeight,
    maxHeight,
    className,
    label,
    language,
}: ICodeEditorProps) => {
    const heightProp = height === undefined ? {} : { height: height }
    const widthProp = width === undefined ? {} : { width: width }
    const minHeightProp = minHeight === undefined ? {} : { minHeight: minHeight }
    const maxHeightProp = maxHeight === undefined ? {} : { maxHeight: maxHeight }
    return (
        <div className={cn("code-editor", "horizontal-scroll", { "code-editor-json-default": language === "json" }, className)}>
            {label && <label>{label}</label>}
            <Editor
                value={code}
                onValueChange={(code) => {
                    if (onChange) {
                        onChange(code)
                    }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                    if (onSaveKey && (e.key === "s" || e.keyCode === 83) && (e.metaKey || e.ctrlKey)) {
                        onSaveKey()
                        e.preventDefault()
                    }
                }}
                highlight={(code) => getHightlight(code ?? "", language)}
                padding={10}
                className="editor"
                preClassName="editor-pre"
                textareaClassName="editor-textarea"
                style={{
                    ...maxHeightProp,
                    ...heightProp,
                    ...minHeightProp,
                    ...widthProp,
                }}
            />
        </div>
    )
}

function getHightlight(code: string, language: TCodeEditorLanguage) {
    if (language === "json") {
        return highlight(code, languages.json, "json")
    }
    if (language === "js") {
        return highlight(code, languages.javascript, "javascript")
    }
    if (language === "css") {
        return highlight(code, languages.css, "css")
    }
    return highlight(code, languages.html, "html")
}

export default CodeEditor
