import React from "react"
import { IModalBase, IModalControlProps } from "./common/modalTypes"
import BaseModal from "./common/BaseModal"
import ModalFooter from "./common/ModalFooter"
import { ModalBody } from "reactstrap"
import { useTranslation } from "react-i18next"
import "./ModalLoggedOut.scss"

export interface IModalLoggedOut extends IModalBase {
    type: "modalLoggedOut"
}

const ModalLoggedOut = ({ title, callback, ...otherProps }: IModalLoggedOut & IModalControlProps) => {
    const { t } = useTranslation()
    return (
        <BaseModal {...otherProps} modalClassName="logged-out-modal" callback={callback} centered title=" ">
            <ModalBody>
                <div>
                    <p className="mb-0 text-center logged-out-text">{t("modalloggedout.text")}</p>
                </div>
            </ModalBody>
            <ModalFooter rightButton={{ onClick: () => document.location.reload(), text: t("modalloggedout.continue") }} />
        </BaseModal>
    )
}

export default ModalLoggedOut
