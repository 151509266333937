import React from "react"
import { Navbar, NavbarToggler, NavbarBrand } from "reactstrap"
import { authService, useShowLoggedInMenus } from "../../services/authService"
import { Link } from "react-navi"
import logo from "../../images/swiipe-logo.png"
import "./NavBar.scss"
import SwitchUserMenu from "./SwitchUserMenu"
import { useSelector } from "react-redux"
import { layoutSelectors, layoutReducerActions } from "../../store/reducers/layoutReducer"
import useReduxDispatch from "../../store/useReduxDispatch"

const NavBar = () => {
    const showLoggedInMenus = useShowLoggedInMenus()
    const sidebarIsOpen = useSelector(layoutSelectors.navBarOpen)
    const dispatch = useReduxDispatch()

    return (
        <Navbar light id="top-navbar" toggleable="" className="d-flex justify-content-between">
            {showLoggedInMenus && (
                <NavbarToggler
                    className="d-sm-none"
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch(layoutReducerActions.setNavBarOpen(!sidebarIsOpen))
                    }}
                    target="sidebar-collapse"
                    id="navbar-toggle-trigger"
                ></NavbarToggler>
            )}
            <NavbarBrand tag="div">
                <Link href="/" exact>
                    <img src={logo} />
                </Link>
            </NavbarBrand>
            <SwitchUserMenu />
        </Navbar>
    )
}

export default NavBar
