import { ApiState, apiReducer } from "./reducers/apiReducer"
import { ConfigurationState, configurationReducer } from "./reducers/configurationReducer"
import { ModalState, modalReducer } from "./reducers/modalReducer"
import { layoutReducer, LayoutState } from "./reducers/layoutReducer"
import { combineReducers } from "redux"
import { UserRelationState, userRelationReducer } from "./reducers/userRelationReducer"
import { contentReducer, ContentState } from "./reducers/contentReducer"

export interface StoreState {
    configuration: ConfigurationState
    userRelation: UserRelationState
    modal: ModalState
    layout: LayoutState
    content: ContentState
    api: ApiState
}

export const createRootReducer = () =>
    combineReducers<StoreState>({
        configuration: configurationReducer,
        userRelation: userRelationReducer,
        modal: modalReducer,
        layout: layoutReducer,
        content: contentReducer,
        api: apiReducer,
    })
