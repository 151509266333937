import React, { useEffect, useRef, useState } from "react"
import "./ContentRichText.scss"
import { IContentItem } from "../../type/doc/IContent"
import { useSelector } from "react-redux"
import { contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import Editor from "../editor/Editor"
import cn from "classnames"
import { useNavigation } from "react-navi"
import { getLocalLink } from "../../util/urlUtil"
import useReduxDispatch from "../../store/useReduxDispatch"

interface IContentRichTextProps {
    item: IContentItem
    className?: string
}

const ContentRichText = ({ item, className }: IContentRichTextProps) => {
    const dispatch = useReduxDispatch()
    const navigation = useNavigation()
    const isInEditorMode = useSelector(contentSelectors.getIsInEditorMode)

    const currentLanguage = useSelector(contentSelectors.getCurrentEditorLanguage)
    const currentLanguageRef = useRef(currentLanguage)
    currentLanguageRef.current = currentLanguage
    const content = item.languages[currentLanguage] ?? ""

    const itemRef = useRef(item)
    itemRef.current = item

    const aTagListener = (e: Event) => {
        e.preventDefault()
        const href = (e.currentTarget as HTMLAnchorElement).href
        const localLink = getLocalLink(href)
        if (localLink) {
            navigation.navigate(localLink)
        } else {
            document.location.href = href
        }
    }

    const containerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (!containerRef.current) {
            return
        }

        const aTags = containerRef.current.getElementsByTagName("A")
        for (let i = 0; i < aTags.length; i++) {
            const aTag = aTags[i]
            aTag.removeEventListener("click", aTagListener)
            if (!isInEditorMode) {
                aTag.addEventListener("click", aTagListener)
            }
        }
    })

    return (
        <div ref={containerRef} className={cn("content-rich-text-comp", !isInEditorMode && "ck-content", className)}>
            {!isInEditorMode && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
            {isInEditorMode && (
                <div>
                    <Editor
                        content={content}
                        onChange={(data: string) => {
                            if (data === itemRef.current.languages[currentLanguageRef.current]) {
                                return
                            }

                            dispatch(
                                contentReducerActions.updateItem({
                                    ...itemRef.current,
                                    languages: {
                                        ...itemRef.current.languages,
                                        [currentLanguageRef.current]: data,
                                    },
                                })
                            )
                        }}
                    ></Editor>
                </div>
            )}
        </div>
    )
}

export default ContentRichText
