import React, { useEffect, useRef } from "react"
import { apiReducerActions, apiSelectors } from "../../store/reducers/apiReducer"
import { useSelector } from "react-redux"
import { getResolvedGroupData } from "../../services/apiGroupService"
import ApiRouteGroup from "./ApiRouteGroup"
import { sortDescending } from "../../util/arrayUtil"
import useReduxDispatch from "../../store/useReduxDispatch"

interface IApiContentProps {}

let potentialFocuses: {
    percent: number
    group: string
    route?: string
}[] = []

let newFocusTimeout: NodeJS.Timeout | undefined

const ApiContent = ({}: IApiContentProps) => {
    const routes = useSelector(apiSelectors.getApiRoutes)
    const docs = useSelector(apiSelectors.getApiDocs)
    const selection = useSelector(apiSelectors.getApiSelection)
    const selectionRef = useRef(selection)
    selectionRef.current = selection

    const dispatch = useReduxDispatch()

    useEffect(() => {
        potentialFocuses = []
    }, [])

    if (!routes || !docs) {
        return null
    }

    const groups = getResolvedGroupData(routes, docs)

    return (
        <div>
            {groups.map((g, i) => (
                <ApiRouteGroup
                    key={g.id}
                    group={g}
                    isFirst={i === 0}
                    onPotentialFocus={(percent, group, route) => {
                        if (
                            selectionRef.current?.disableScrollSelectionUntil &&
                            selectionRef.current.disableScrollSelectionUntil.getTime() > new Date().getTime()
                        ) {
                            // Ignore while autoscrolling because of menu click
                            return
                        }
                        potentialFocuses.push({ percent, group, route })
                        if (!newFocusTimeout) {
                            newFocusTimeout = setTimeout(() => {
                                const best = sortDescending(potentialFocuses, (f) => f.percent)[0]
                                if (best) {
                                    dispatch(
                                        apiReducerActions.setApiSelection({
                                            selectedBy: "scrolling",
                                            selectedGroup: best.group,
                                            selectedRoute: best.route,
                                        })
                                    )
                                }
                                newFocusTimeout = undefined
                                potentialFocuses = []
                            }, 50)
                        }
                    }}
                />
            ))}
        </div>
    )
}

export default ApiContent
