import React, { useEffect, Suspense } from "react"
import { View, Router, NotFoundBoundary } from "react-navi"
import { useSelector } from "react-redux"
import { initializeThunk } from "./../store/thunks/startupThunks"
import { configurationSelectors } from "./../store/reducers/configurationReducer"
import MainLayout from "../components/layouts/MainLayout"
import { navigationService } from "../services/navigationService"
import { INavigationContext } from "./INavigationContext"
import NotFoundPage from "../pages/NotFoundPage"
import { mainRoutes } from "./mainRoutes"
import { userRelationSelectors } from "./../store/reducers/userRelationReducer"
import useReduxDispatch from "../store/useReduxDispatch"

interface IProps {}

const MainRouter = ({}: IProps) => {
    const dispatch = useReduxDispatch()
    const initialized = useSelector(configurationSelectors.initialized)
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)

    useEffect(() => {
        dispatch(initializeThunk())
    }, [])

    if (!initialized) {
        return null
    }

    const navigationContext: INavigationContext = { currentUserRelation }
    const navigation = navigationService.setup(navigationContext, mainRoutes)

    return (
        <Router navigation={navigation} context={navigationContext}>
            <MainLayout>
                <NotFoundBoundary render={() => <NotFoundPage />}>
                    <Suspense fallback={null}>
                        <View />
                    </Suspense>
                </NotFoundBoundary>
            </MainLayout>
        </Router>
    )
}

export default MainRouter
