import React, { useEffect } from "react"
import PageContainer from "../../components/page/PageContainer"
import PageWrapper from "../../components/page/PageWrapper"
import { useSelector } from "react-redux"
import { StoreState } from "../../store/StoreState"
import useReduxDispatch from "../../store/useReduxDispatch"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import { contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import ContentPageContainer from "../../components/content/ContentPageContainer"
import { fetchContentGroupThunk } from "../../store/thunks/contentThunks"
import { tryToRedirect } from "../../services/redirectService"
import { configurationSelectors } from "../../store/reducers/configurationReducer"

interface IContentPageProps {
    path: string
}

const ContentPage = ({ path }: IContentPageProps) => {
    const page = useSelector((state: StoreState) => contentSelectors.getGroupByLocator(state, path))
    const dispatch = useReduxDispatch()
    const isRedirectsDisabled = useSelector(configurationSelectors.isRedirectsDisabled)

    useEffect(() => {
        if (!isRedirectsDisabled) {
            if (tryToRedirect(path)) {
                return
            }
        }

        if (!page) {
            dispatch(fetchContentGroupThunk("docpage", path))
        }
    }, [path])

    useEffect(() => {
        dispatch(contentReducerActions.setCurrentContentGroup(page))
    }, [page])

    if (!page) {
        return null
    }

    return (
        <PageWrapper mediumWidth>
            <PageContainer>
                <SpinnerContainer showSpinner={!page}>
                    {page && <ContentPageContainer page={page} />}
                    {!page && <div style={{ height: "300px" }}></div>}
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default ContentPage
