export function getUrlSchemeAndHost() {
    return document.location.protocol + "//" + document.location.host
}

export function getQueryValueFromCurrentUrl(queryToLookFor: string) {
    const query = new URLSearchParams(decodeURIComponent(document.location.search))
    return query.get(queryToLookFor)
}

export function removeQueryValueFromCurrentUrl(queryToDelete: string) {
    const query = new URLSearchParams(document.location.search)
    query.delete(queryToDelete)
    const updatedQuery = !query.toString() ? "" : "?" + query.toString()
    window.history.replaceState(null, document.title, getUrlSchemeAndHost() + document.location.pathname + updatedQuery)
}

export function getCurrentUrlPath(includeSchemeAndHost: boolean, queryParams?: { [key: string]: string }) {
    const queryString = !queryParams
        ? ""
        : Object.keys(queryParams).reduce((qStr, key) => {
              const part = key + "=" + queryParams[key]
              return qStr.length === 0 ? part : qStr + "&" + part
          }, "")

    return (
        (includeSchemeAndHost ? getUrlSchemeAndHost() : "") +
        document.location.pathname +
        (queryString.length === 0 ? "" : "?" + queryString)
    )
}

function getIsLocalLink(link: string) {
    if (!link.startsWith("http")) {
        return true
    }

    const domainWithPort = link.replace("https://", "").replace("http://", "").split("/")[0]
    const domain = domainWithPort.split(":")[0]
    return domain.endsWith("docs.swiipe.com")
}

export function getLocalLink(link: string) {
    const isLocalLink = getIsLocalLink(link)
    if (!isLocalLink) {
        return undefined
    }

    if (link.startsWith("http")) {
        const withoutScheme = link.replace("https://", "").replace("http://", "").split("/")
        withoutScheme.shift() // remove domain
        link = withoutScheme.join("/")
    }

    if (link.startsWith("/")) {
        return link
    }
    return "/" + link
}
