import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { TAllModalsWithCallback } from "../../components/modal/common/SingleModal"

export type ModalState = Readonly<{
    modals: TAllModalsWithCallback[]
}>
const initialState: ModalState = { modals: [] }

export const modalReducerActions = {
    addModal: (modal: TAllModalsWithCallback) => createAction("APP/ADD_MODAL", { modal }),
    removeModal: (modal: TAllModalsWithCallback) => createAction("APP/REMOVE_MODAL", { modal }),
}

export type ModalActions = ActionsUnion<typeof modalReducerActions>

export const modalReducer = (state: ModalState = initialState, action: ModalActions) => {
    switch (action.type) {
        case "APP/ADD_MODAL": {
            if (state.modals.find((m) => m.type === action.modal.type)) {
                // Modal of type already shown
                action.modal.callback("ignored")
                return state
            }
            return {
                ...state,
                modals: [...state.modals, action.modal],
            }
        }
        case "APP/REMOVE_MODAL": {
            return {
                ...state,
                modals: state.modals.filter((m) => m !== action.modal),
            }
        }
    }

    return state
}

export const modalSelectors = {
    modals: (state: StoreState) => state.modal.modals,
}
