import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { IUserRelation, IUserRelationFlattened } from "../../type/IUserRelation"
import { setUserRelationToLocalStorage, flattenUserRelations } from "../../services/userRelationService"
import { TUserRelation } from "../../type/TUserRelation"
import { TUserRole } from "../../type/TUserRole"
import { IRelationForOrganization } from "../../type/IRelationsForOrganization"

export type UserRelationState = Readonly<{
    userRelations?: IUserRelation[]
    isMasterPartner?: boolean
    userRelationsFlattened?: IUserRelationFlattened[]
    currentRelation?: IUserRelationFlattened
    lastUsedRelations: string[]
    relationsForOrganizations: { [orgId: string]: IRelationForOrganization[] }
}>
const initialState: UserRelationState = { relationsForOrganizations: {}, lastUsedRelations: [] }

export const userRelationReducerActions = {
    setUserRelations: (userRelations: IUserRelation[]) => createAction("APP/SET_USER_RELATIONS", { userRelations }),
    setCurrentRelation: (currentRelation: IUserRelationFlattened) =>
        createAction("APP/SET_CURRENT_RELATION", { currentRelation }),
    setRelationsForOrganization: (orgId: string, relations: IRelationForOrganization[]) =>
        createAction("APP/SET_RELATIONS_FOR_ORGANIZATION", { orgId, relations }),
    initLastUsedRelations: (lastUsedRelations: IUserRelationFlattened[]) =>
        createAction("APP/INIT_LASTUSED_RELATIONS", { lastUsedRelations }),
    addLastUsedRelation: (lastUsedRelationId: string) =>
        createAction("APP/ADD_NEW_ID_LASTUSED_RELATIONS", { lastUsedRelationId }),
}

export type UserRelationActions = ActionsUnion<typeof userRelationReducerActions>

export const userRelationReducer = (state: UserRelationState = initialState, action: UserRelationActions): UserRelationState => {
    switch (action.type) {
        case "APP/SET_USER_RELATIONS": {
            return {
                ...state,
                userRelations: action.userRelations,
                userRelationsFlattened: flattenUserRelations(action.userRelations),
                isMasterPartner: !!action.userRelations.find((ur) => ur.isMasterPartner),
            }
        }
        case "APP/SET_CURRENT_RELATION": {
            setUserRelationToLocalStorage(action.currentRelation)
            return {
                ...state,
                currentRelation: action.currentRelation,
            }
        }
        case "APP/SET_RELATIONS_FOR_ORGANIZATION": {
            return {
                ...state,
                relationsForOrganizations: {
                    ...state.relationsForOrganizations,
                    [action.orgId]: action.relations,
                },
            }
        }
        case "APP/INIT_LASTUSED_RELATIONS": {
            return {
                ...state,
                lastUsedRelations: action.lastUsedRelations.map((relation) => relation.id).slice(0, 5),
            }
        }
        case "APP/ADD_NEW_ID_LASTUSED_RELATIONS": {
            return {
                ...state,
                lastUsedRelations: state.lastUsedRelations.includes(action.lastUsedRelationId)
                    ? [
                          state.lastUsedRelations[0],
                          ...state.lastUsedRelations.filter(
                              (id) => id === action.lastUsedRelationId && id !== state.lastUsedRelations[0]
                          ),
                          ...state.lastUsedRelations.filter(
                              (id) => id !== action.lastUsedRelationId && id !== state.lastUsedRelations[0]
                          ),
                      ]
                    : [state.lastUsedRelations[0], action.lastUsedRelationId, ...state.lastUsedRelations.slice(1, 4)],
            }
        }
    }

    return state
}

export const userRelationSelectors = {
    userRelationsFlattened: (state: StoreState) => state.userRelation.userRelationsFlattened,
    userRelations: (state: StoreState) => state.userRelation.userRelations,
    currentUserRelation: (state: StoreState) => state.userRelation.currentRelation,
    currentUserRelationType: (state: StoreState): TUserRelation =>
        state.userRelation.currentRelation ? state.userRelation.currentRelation.relationType : "Anonymous",
    currentUserRelationRole: (state: StoreState): TUserRole =>
        state.userRelation.currentRelation ? state.userRelation.currentRelation.userRole : TUserRole.None,
    relationsForOrganization: (state: StoreState, orgId: string): IRelationForOrganization[] =>
        state.userRelation.relationsForOrganizations[orgId],
    lastUsedRelations: (state: StoreState) => state.userRelation.lastUsedRelations,
    isMasterPartner: (state: StoreState) => state.userRelation.isMasterPartner,
}
