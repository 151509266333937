import { userRelationSelectors } from "./../reducers/userRelationReducer"
import { getCurrentLanguage } from "./../../locale/lang"
import { contentReducerActions, contentSelectors } from "./../reducers/contentReducer"
import { IContentGroup, IServerContentItem, TContent } from "../../type/doc/IContent"
import { endpoints } from "./../../data/endpoints"
import { requestThunk } from "./serverThunks"
import { ThunkAction } from "redux-thunk"
import { StoreState } from "../StoreState"
import { AnyAction } from "redux"
import { createGuid } from "../../util/stringUtil"
import { getContentSortValue, mapFromServerContentItems, mapToServerContentItems } from "../../services/contentService"
import { flattenArray, sortAscending } from "../../util/arrayUtil"

// i18next is not ready when the reducer loads, so we need to set the default language a little bit later
export const setContentDefaultLanguage =
    (): ThunkAction<Promise<void>, StoreState, null, AnyAction> => async (dispatch, getState) => {
        dispatch(contentReducerActions.setCurrentLanguage(getCurrentLanguage()))
    }

export const fetchContentGroupsThunk =
    (type: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const res = await dispatch(
            requestThunk<{ contentGroups: IContentGroup[]; items: IServerContentItem[] }>(endpoints.Content.getGroups, {
                params: {
                    type: type,
                    includeDepth: 2,
                },
            })
        )

        const itemsPerId: { [itemId: string]: IServerContentItem[] } = res.items.reduce((res, item) => {
            let arr = res[item.itemId]
            if (!arr) {
                arr = []
                res[item.itemId] = arr
            }
            arr.push(item)
            return res
        }, {})

        dispatch(
            contentReducerActions.addContentGroups(
                res.contentGroups,
                Object.keys(itemsPerId).map((itemId) => mapFromServerContentItems(itemsPerId[itemId]))
            )
        )
    }

export const fetchContentGroupThunk =
    (type: string, locator: string, force?: boolean): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const groupFromState = contentSelectors.getGroupByLocator(getState(), locator)
        if (!force && groupFromState) {
            return
        }

        const isMasterPartner = userRelationSelectors.isMasterPartner(getState())

        const res = await dispatch(
            requestThunk<{ contentGroup: IContentGroup; items: IServerContentItem[] }>(endpoints.Content.getGroupByLocator, {
                params: {
                    type: type,
                    locator: locator,
                    includeDepth: 2,
                    restrictToLanguage: isMasterPartner ? "" : getCurrentLanguage(),
                    fallBackToLanguage: isMasterPartner ? "" : "en",
                },
            })
        )

        const itemsPerId: { [itemId: string]: IServerContentItem[] } = res.items.reduce((res, item) => {
            let arr = res[item.itemId]
            if (!arr) {
                arr = []
                res[item.itemId] = arr
            }
            arr.push(item)
            return res
        }, {})

        dispatch(
            contentReducerActions.addContentGroups(
                [res.contentGroup],
                Object.keys(itemsPerId).map((itemId) => mapFromServerContentItems(itemsPerId[itemId]))
            )
        )
    }

export const addOrUpdateContentGroupThunk =
    (type: string, locator: string, itemId?: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        await dispatch(
            requestThunk(itemId ? endpoints.Content.updateGroup(itemId) : endpoints.Content.addGroup, {
                data: {
                    contentGroup: {
                        locator: locator,
                        type: type,
                    },
                },
            })
        )
    }

export const deleteContentGroupThunk =
    (itemId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const group = contentSelectors.getGroup(getState(), itemId)
        if (!group) {
            return
        }
        await dispatch(requestThunk(endpoints.Content.deleteGroup(itemId)))
        dispatch(contentReducerActions.removeGroup(group.itemId))
    }

export const addOrUpdateGroupContentItemsThunk =
    (groupId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const items = contentSelectors.getContentItemForParent(getState(), groupId)
        const sorted = sortAscending(items, (i) => getContentSortValue(i))
        sorted.forEach((item, index) => (item.locationInParent = index * 1000 + ""))

        const itemsForServer = flattenArray(items.map((i) => mapToServerContentItems(i)))
        await dispatch(
            requestThunk(endpoints.Content.updateContentItems, {
                data: {
                    contentItems: itemsForServer,
                },
            })
        )
        const group = contentSelectors.getGroup(getState(), groupId)
        await dispatch(fetchContentGroupThunk(group!.type, group!.locator, true))
    }

export const addUnsavedItemThunk =
    (parentId: string, type: TContent, locationInParent: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        dispatch(
            contentReducerActions.addItems([
                {
                    itemId: createGuid(),
                    languages: { en: "" },
                    locationInParent: locationInParent,
                    parentId: parentId,
                    type: type,
                    unsaved: true,
                },
            ])
        )
    }
