import React from "react"
import { Container } from "reactstrap"
import NavMenu from "./../menu/NavMenu"
import { useShowLoggedInMenus } from "../../services/authService"
import NavBar from "./../menu/NavBar"
import Sidebar from "./../menu/Sidebar"
import PageFooter from "../footer/PageFooter"
import ModalRenderer from "./../modal/common/ModalRenderer"
import { useCurrentRoute } from "react-navi"
import cn from "classnames"

interface IProps {
    children?: React.ReactNode
}
const MainLayout = ({ children }: IProps) => {
    const showLoggedInMenus = useShowLoggedInMenus()
    const route = useCurrentRoute()
    const isApiPage = route.url.pathname.startsWith("/api")

    return (
        <div className={cn("swiipe-outer-container")}>
            <div id="cookie-expiration-extender"></div>
            <Container id="app-container" fluid>
                <div className={!showLoggedInMenus ? "no-sidebar" : ""} id={showLoggedInMenus ? "wrapper" : ""}>
                    <Sidebar>
                        <NavMenu />
                    </Sidebar>
                    <div>
                        <div id="main-col">
                            <div id="main">
                                {!isApiPage && <NavBar />}
                                {children}
                            </div>
                            <PageFooter />
                        </div>
                    </div>
                </div>
                <ModalRenderer />
            </Container>
        </div>
    )
}
export default MainLayout
