import React from "react"
import "./SwiipeFrame.scss"
import cn from "classnames"

interface ISwiipeFrameProps {
    children?: React.ReactNode
    invert?: boolean
    blackwhite?: boolean
}

const SwiipeFrame = ({ children, invert, blackwhite }: ISwiipeFrameProps) => {
    return (
        <span className={cn("swiipe-frame", { invert, blackwhite })}>
            {children && <span className="swiipe-frame-content">{children}</span>}
        </span>
    )
}

export default SwiipeFrame
