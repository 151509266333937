import React from "react"
import "./SwiipeBox.scss"
import cn from "classnames"

interface ISwiipeBoxProps {
    children?: React.ReactNode
    title: string
    padding?: number
    invert?: boolean
}

const SwiipeBox = ({ title, children, padding, invert }: ISwiipeBoxProps) => {
    return (
        <div className={cn("swiipe-box", { invert: invert })}>
            <div className="swiipe-box-title">{title}</div>
            <div style={{ padding }} className="swiipe-box-content">
                {children}
            </div>
        </div>
    )
}

export default SwiipeBox
