import React from "react"
import { apiReducerActions, apiSelectors } from "../../store/reducers/apiReducer"
import { useSelector } from "react-redux"
import { getResolvedGroupData } from "../../services/apiGroupService"
import "./ApiMenu.scss"
import { NavbarBrand } from "reactstrap"
import { Link } from "react-navi"
import logo from "../../images/swiipe-logo.png"
import cn from "classnames"
import { getApiRouteKey } from "../../services/apiRouteService"
import { addMiliseconds, getFormattedDate } from "../../util/dateUtil"
import StandardButtonWithSpinner from "../buttons/StandardButtonWithSpinner"
import useReduxDispatch from "../../store/useReduxDispatch"
import { ensureNewestApiDocs } from "../../store/thunks/apiThunks"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { configurationSelectors } from "../../store/reducers/configurationReducer"

interface IApiMenuProps {}

const ApiMenu = ({}: IApiMenuProps) => {
    const routes = useSelector(apiSelectors.getApiRoutes)
    const docs = useSelector(apiSelectors.getApiDocs)
    const lastUpdated = useSelector(apiSelectors.getApiLastUpdated)
    const selection = useSelector(apiSelectors.getApiSelection)
    const isMasterPartner = useSelector(userRelationSelectors.isMasterPartner)
    const env = useSelector(configurationSelectors.environment)
    const dispatch = useReduxDispatch()

    const groups = getResolvedGroupData(routes || [], docs || [])

    return (
        <div className="api-menu-comp">
            <NavbarBrand tag="div" className="logo">
                <img src={logo} />
            </NavbarBrand>
            {routes &&
                docs &&
                groups.map((g) => {
                    const hasSelectedRoute =
                        g.routeWithDocList && g.routeWithDocList.find((r) => getApiRouteKey(r.route) === selection?.selectedRoute)
                    return (
                        <div
                            key={g.id}
                            className={cn(
                                "group",
                                selection?.selectedGroup === g.id && !selection?.selectedRoute && "selected",
                                hasSelectedRoute && "route-selected"
                            )}
                        >
                            <div
                                className="group-name"
                                onClick={() => {
                                    dispatch(
                                        apiReducerActions.setApiSelection({
                                            selectedBy: "menu",
                                            selectedGroup: g.id,
                                            disableScrollSelectionUntil: addMiliseconds(new Date(), 1000),
                                        })
                                    )
                                }}
                            >
                                {g.info?.title ?? "Other"}
                            </div>
                            {g.routeWithDocList && selection?.selectedGroup === g.id && (
                                <div className="routes">
                                    {g.routeWithDocList.map((r) => {
                                        return (
                                            <div
                                                className={cn(
                                                    "route",
                                                    selection?.selectedRoute === getApiRouteKey(r.route) && "selected"
                                                )}
                                                key={getApiRouteKey(r.route)}
                                                onClick={() => {
                                                    dispatch(
                                                        apiReducerActions.setApiSelection({
                                                            selectedBy: "menu",
                                                            selectedGroup: g.id,
                                                            selectedRoute: getApiRouteKey(r.route),
                                                            disableScrollSelectionUntil: addMiliseconds(new Date(), 1000),
                                                        })
                                                    )
                                                }}
                                            >
                                                {r.docMethod.summary ?? ""}
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            {env !== "Development" && isMasterPartner && (
                <div className="api-updater">
                    <StandardButtonWithSpinner
                        title="Check for update"
                        isSmall
                        onClick={async () => {
                            await dispatch(ensureNewestApiDocs())
                        }}
                    />
                    <div className="last-updated">
                        Last updated: {lastUpdated ? getFormattedDate(lastUpdated, "dash-international") : ""}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ApiMenu
