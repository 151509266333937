import { IApiEndpoint, TEndpointName } from "./../type/request/IApiEndpoint"
import { THttpMethod } from "./../type/request/THttpMethod"

export interface IRequestOptions {
    isOpen?: boolean
    endpoint?: TEndpointName
}

function create0(method: THttpMethod, path: string, options?: IRequestOptions): IApiEndpoint {
    return {
        method,
        endpoint: options?.endpoint ?? "InternalApi",
        authentication: options?.isOpen ? "open" : "login",
        path,
    }
}

function create1(method: THttpMethod, path: string, options?: IRequestOptions): (param1: string) => IApiEndpoint {
    return (param1: string) => {
        return create0(method, path.replace(/{[^}]*}/, param1), options)
    }
}

function create2(method: THttpMethod, path: string, options?: IRequestOptions): (param1: string, param2: string) => IApiEndpoint {
    return (param1: string, param2: string) => {
        return create0(method, path.replace(/{[^}]*}/, param1).replace(/{[^}]*}/, param2), options)
    }
}

const apiEndpoints = {
    Self: {
        checkCookie: create0("post", "/checkCookie", { endpoint: "Self" }),
        getConfiguration: create0("get", "/api/configuration/GetServerConfigurationEndpoints", { endpoint: "Self" }),
    },
    Auth: { login: create0("post", "/auth/login", { isOpen: true }) },
    Content: {
        getGroupByLocator: create0("get", "/content/content/contentGroup"),
        getGroups: create0("get", "/content/content/contentGroups"),
        addGroup: create0("post", "/content/content/contentGroups"),
        updateGroup: create1("put", "/content/content/contentGroups/{id}"),
        deleteGroup: create1("delete", "/content/content/contentGroups/{id}"),
        updateContentItems: create0("put", "/content/content/contentItems"),
        uploadImage: create0("post", "/content/content/image"),
        getApiDoc: create0("get", "/content/apiDoc", { isOpen: true }),
        addApiDoc: create0("post", "/content/apiDoc"),
    },
    Core: {
        getUserRelations: create0("get", "/core/userRelation/userRelations"),
        getUsersForOrganization: create2("get", "/core/userRelation/users/{organizationType}/{organizationId}"),
    },
    MainApi: {
        getApiSpec: create0("get", "/internalapi/docs/getDocsConfig", { endpoint: "MainApi" }),
    },
}

type IApiEndpoints = typeof apiEndpoints
export const endpoints: IApiEndpoints = apiEndpoints
