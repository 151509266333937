import { getAllLanguages, TLanguage } from "./../type/TLanguage"
import i18next from "i18next"
import { initReactI18next } from "react-i18next"

const supportedLanguage = getAllLanguages()

export const initializeLocales = () =>
    i18next
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                en: {
                    translation: require("./lang/en.json"),
                },
                da: {
                    translation: require("./lang/da.json"),
                },
            },
            lng: navigator.language.split("-")[0],
            fallbackLng: "da",

            interpolation: {
                escapeValue: false,
            },
        })

export const getText = (textId: string) => {
    return i18next.t(textId)
}

export const getCurrentLanguage = (): TLanguage => {
    const lang = i18next.language as TLanguage
    if (!supportedLanguage.includes(lang)) {
        return "en"
    }
    return lang
}
