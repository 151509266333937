import React from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import CustomEditor from "ckeditor5-custom-build/build/ckeditor"
import { endpoints } from "../../data/endpoints"
import { useSelector } from "react-redux"
import { StoreState } from "../../store/StoreState"
import { configurationSelectors } from "../../store/reducers/configurationReducer"

// CustomEditor.builtinPlugins.map((plugin: any) => plugin.pluginName)

interface IEditorProps {
    content: string
    onChange: (content: string) => void
}

const Editor = ({ content, onChange }: IEditorProps) => {
    const uploadImageEndpoint = useSelector((state: StoreState) =>
        configurationSelectors.endpoint(state, endpoints.Content.uploadImage)
    )
    return (
        <CKEditor
            config={{
                toolbar: [
                    "heading",
                    "link",
                    "imageUpload",
                    "alignment",
                    "|",
                    "bold",
                    "italic",
                    "underline",
                    "removeFormat",
                    "codeBlock",
                    "code",
                    "blockQuote",
                    "numberedList",
                    "bulletedList",
                    "|",
                    "undo",
                    "redo",
                    "|",
                    "imageInsert",
                    "mediaEmbed",
                    "insertTable",
                    "|",
                    "indent",
                    "outdent",
                ],
                table: {
                    contentToolbar: ["tableRow", "tableColumn", "mergeTableCells"],
                    // tableToolbar: ["blockQuote"],
                },
                alignment: {
                    options: ["left", "center", "right"],
                },
                simpleUpload: {
                    uploadUrl: uploadImageEndpoint,
                    withCredentials: true,
                },
                codeBlock: {
                    languages: [
                        { language: "javascript", label: "JavaScript" },
                        { language: "plaintext", label: "Plain text" },
                    ],
                },
                link: {
                    decorators: {
                        mode: "manual",
                    },
                    addTargetToExternalLinks: true,
                },
                image: {
                    styles: ["alignLeft", "alignCenter", "alignRight"],

                    // Configure the available image resize options.
                    resizeOptions: [
                        {
                            name: "imageResize:original",
                            label: "Original",
                            value: null,
                        },
                        {
                            name: "imageResize:50",
                            label: "50%",
                            value: "50",
                        },
                        {
                            name: "imageResize:75",
                            label: "75%",
                            value: "75",
                        },
                    ],

                    // You need to configure the image toolbar, too, so it shows the new style
                    // buttons as well as the resize buttons.
                    toolbar: [
                        "imageStyle:alignLeft",
                        "imageStyle:alignCenter",
                        "imageStyle:alignRight",
                        "|",
                        "imageResize",
                        "|",
                        "imageTextAlternative",
                    ],
                },
                heading: {
                    options: [
                        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                        { model: "heading1", view: "h1", title: "Title (h1)", class: "ck-heading_heading1" },
                        { model: "heading2", view: "h2", title: "h2", class: "ck-heading_heading2" },
                        { model: "heading3", view: "h3", title: "Section title (h3)", class: "ck-heading_heading3" },
                        { model: "heading4", view: "h4", title: "h4", class: "ck-heading_heading4" },
                        { model: "heading5", view: "h5", title: "Small section title (h5)", class: "ck-heading_heading5" },
                        {
                            model: "custom",
                            view: {
                                name: "div",
                                classes: "custom",
                            },
                            title: "Custom",
                            class: "ck-custom-style",
                        },
                    ],
                },
            }}
            editor={CustomEditor}
            data={content}
            onChange={(event: any, editor: any) => {
                const data = editor.getData()
                onChange(data)
            }}
        ></CKEditor>
    )
}

export default Editor
