import React from "react"
import Spinner from "../../images/load/spinner.gif"
import cn from "classnames"
import "./SpinnerContainer.scss"

interface ISpinnerContainerProps {
    showSpinner: boolean
    className?: string
    children?: React.ReactNode
}

const SpinnerContainer = ({ children, showSpinner, className }: ISpinnerContainerProps) => {
    return (
        <div className={cn("swiipe-spinner-container clearfix", className)}>
            <div className={cn("child-wrapper", { hide: showSpinner })}>{children}</div>
            {showSpinner && <img className="spinner" src={Spinner} />}
        </div>
    )
}

export default SpinnerContainer
