import React from "react"
import "./ResolvedContent.scss"
import cn from "classnames"
import { standardTexts } from "../../data/standardTexts"

interface IResolvedContentProps {
    content: string
}

const ResolvedContent = ({ content }: IResolvedContentProps) => {
    let cont = content.replace(/{{(.*?)}}/g, (all: string, id: string) => {
        return standardTexts.find((s) => s.identifier === id)?.description ?? ""
    })
    cont = cont.replace(/\[\[(.*?)\]\]/g, `<span class="tag">$1</span>`)
    return <span className="resolved-content-comp" dangerouslySetInnerHTML={{ __html: cont }}></span>
}

export default ResolvedContent
