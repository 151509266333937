import { getCurrentLanguage } from "./../../locale/lang"
import { TLanguage } from "./../../type/TLanguage"
import { IContentItem, IContentGroup } from "../../type/doc/IContent"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"
import { toDict } from "../../util/arrayUtil"
import { removeFromObject, removeKeyFromObject } from "../../util/objectUtil"

export type ContentState = Readonly<{
    groups: { [itemId: string]: IContentGroup }
    currentContentGroup?: string
    items: { [itemId: string]: IContentItem }
    isInEditorMode: boolean
    currentEditorLanguage: TLanguage
}>
const initialState: ContentState = { groups: {}, items: {}, isInEditorMode: false, currentEditorLanguage: getCurrentLanguage() }

export const contentReducerActions = {
    addContentGroups: (groups: IContentGroup[], items?: IContentItem[]) =>
        createAction("APP/CONTENT_ADD_GROUPS", { groups, items }),
    setCurrentContentGroup: (group?: IContentGroup) => createAction("APP/CONTENT_SET_CURRENT_GROUP", { group }),
    addItems: (items: IContentItem[]) => createAction("APP/CONTENT_ADD_ITEMS", { items }),
    removeGroup: (itemId: string) => createAction("APP/CONTENT_REMOVE_GROUP", { itemId }),
    removeItem: (itemId: string) => createAction("APP/CONTENT_REMOVE_ITEM", { itemId }),
    updateItem: (item: IContentItem) => createAction("APP/CONTENT_UPDATE_ITEM", { item }),
    setEditorMode: (inEditorMode: boolean) => createAction("APP/CONTENT_SET_EDITOR_MODE", { inEditorMode }),
    setCurrentLanguage: (language: TLanguage) => createAction("APP/CONTENT_SET_CURRENT_EDITOR_LANGUAGE", { language }),
}

export type ContentActions = ActionsUnion<typeof contentReducerActions>

export const contentReducer = (state: ContentState = initialState, action: ContentActions): ContentState => {
    switch (action.type) {
        case "APP/CONTENT_ADD_GROUPS": {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    ...toDict(action.groups, (g) => g.itemId),
                },
                items: {
                    ...removeFromObject(state.items, (i) => !!action.groups.find((g) => g.itemId === i.parentId)),
                    ...toDict(action.items ?? [], (i) => i.itemId),
                },
            }
        }
        case "APP/CONTENT_SET_CURRENT_GROUP": {
            return {
                ...state,
                currentContentGroup: action.group?.itemId,
            }
        }
        case "APP/CONTENT_ADD_ITEMS": {
            return {
                ...state,
                items: {
                    ...state.items,
                    ...toDict(action.items ?? [], (i) => i.itemId),
                },
            }
        }
        case "APP/CONTENT_REMOVE_GROUP": {
            return {
                ...state,
                groups: removeKeyFromObject(state.groups, action.itemId),
                items: removeFromObject(state.items, (i) => i.parentId === action.itemId),
            }
        }
        case "APP/CONTENT_REMOVE_ITEM": {
            return {
                ...state,
                items: removeFromObject(state.items, (i) => i.itemId === action.itemId),
            }
        }
        case "APP/CONTENT_UPDATE_ITEM": {
            return {
                ...state,
                items: {
                    ...state.items,
                    [action.item.itemId]: action.item,
                },
            }
        }
        case "APP/CONTENT_SET_CURRENT_EDITOR_LANGUAGE": {
            return {
                ...state,
                currentEditorLanguage: action.language,
            }
        }
        case "APP/CONTENT_SET_EDITOR_MODE": {
            return {
                ...state,
                isInEditorMode: action.inEditorMode,
            }
        }
    }

    return state
}

export const contentSelectors = {
    getGroupByLocator: (state: StoreState, locator: string) =>
        Object.values(state.content.groups).find((g) => g.locator === locator),
    getGroup: (state: StoreState, itemId: string) => Object.values(state.content.groups).find((p) => p.itemId === itemId),
    getGroups: (state: StoreState) => state.content.groups,
    getCurrentGroup: (state: StoreState) => state.content.currentContentGroup,
    getIsInEditorMode: (state: StoreState) => state.userRelation.isMasterPartner && state.content.isInEditorMode,
    getCurrentEditorLanguage: (state: StoreState): TLanguage => state.content.currentEditorLanguage,
    getContentItemForParent: (state: StoreState, parentId: string) =>
        state.content.items && Object.values(state.content.items).filter((i) => i.parentId === parentId),
}
