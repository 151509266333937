import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type LayoutState = Readonly<{
    navBarOpen: boolean
}>
const initialState: LayoutState = { navBarOpen: false }

export const layoutReducerActions = {
    setNavBarOpen: (open: boolean) => createAction("APP/SET_NAV_BAR_OPEN", { open }),
}

export type LayoutActions = ActionsUnion<typeof layoutReducerActions>

export const layoutReducer = (state: LayoutState = initialState, action: LayoutActions): LayoutState => {
    switch (action.type) {
        case "APP/SET_NAV_BAR_OPEN": {
            return {
                ...state,
                navBarOpen: action.open,
            }
        }
    }

    return state
}

export const layoutSelectors = {
    navBarOpen: (state: StoreState) => state.layout.navBarOpen,
}
