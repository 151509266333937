import React from "react"
import { Button, ButtonProps } from "reactstrap"
import cn from "classnames"
import "./StandardButton.scss"

export interface IStandardButtonBaseProps {
    variant?: "primary"
    type?: "submit"
    className?: string
    id?: string
    width?: number
    title?: string
    dark?: boolean
    noBorder?: boolean
    isGrey?: boolean
    inverted?: boolean
    disabled?: boolean
    isDelete?: boolean
    isBlue?: boolean
    isLarge?: boolean
    isSmall?: boolean
    isTiny?: boolean
    children?: React.ReactNode
}

export interface IStandardButtonProps extends IStandardButtonBaseProps {
    onClick?: (event: React.MouseEvent<any, MouseEvent>) => void
}

const StandardButton = ({
    className,
    dark,
    noBorder,
    isGrey,
    inverted,
    onClick,
    id,
    width,
    title,
    disabled,
    isDelete,
    isBlue,
    isLarge,
    isSmall,
    isTiny,
    type,
    variant,
    children,
}: IStandardButtonProps) => {
    const widthProp = width ? { width: width + "px" } : {}
    return (
        <Button
            onClick={onClick}
            id={id}
            className={cn(
                "swiipe-standard-button swiipe-btn",
                {
                    "swiipe-btn-white": inverted && !isDelete && !isBlue,
                    "swiipe-btn-dark": dark,
                    "border-0": noBorder,
                    inverted,
                    "small-btn": isSmall,
                    "tiny-btn": isTiny,
                    delete: isDelete,
                    grey: isGrey,
                    blue: isBlue,
                    large: isLarge,
                },
                className
            )}
            style={{ ...widthProp }}
            variant={variant || "primary"}
            disabled={disabled}
            type={type}
        >
            {title}
            {children}
        </Button>
    )
}

export default StandardButton
