import { ThunkAction } from "redux-thunk"
import { StoreState } from "./../StoreState"
import { AnyAction } from "redux"
import { serverServiceExecuteRequest } from "../../services/serverService"
import { AxiosRequestConfig } from "axios"
import { addModalThunk } from "./modalThunks"
import { navigationService } from "../../services/navigationService"
import { IApiEndpoint } from "../../type/request/IApiEndpoint"
import { THttpMethod } from "../../type/api/apiDocTypes"
import { configurationSelectors } from "../reducers/configurationReducer"

export interface RequestConfig {
    noErrorAlert?: boolean
    defaultErrorMessage?: string
    errorHandlers?: ErrorHandler[]
}

export interface ErrorHandler {
    errorCode: string
    handleError: (err: string) => Promise<boolean>
}

export const requestThunk =
    <T>(
        endpointConfig: IApiEndpoint,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const endpointBase = configurationSelectors.endpointConfig(getState(), endpointConfig.endpoint)
        const response = await dispatch(
            makeRequestThunk<T>(
                endpointConfig.method,
                endpointBase + endpointConfig.path,
                {
                    ...axiosConfig,
                    withCredentials: endpointConfig.authentication === "login",
                },
                requestConfig
            )
        )
        return response
    }

export const makeRequestThunk =
    <T>(
        method: THttpMethod,
        url: string,
        axiosConfig?: AxiosRequestConfig,
        requestConfig?: RequestConfig
    ): ThunkAction<Promise<T>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        try {
            const response = await serverServiceExecuteRequest<T>(method, url, axiosConfig, requestConfig)
            return response
        } catch (error: any) {
            const noErrors = requestConfig && requestConfig.noErrorAlert
            if (!noErrors && !!error.errorToAlert) {
                if (error.isFallbackError) {
                    const result = await dispatch(addModalThunk({ type: "error", errorMessage: error.errorToAlert }))
                } else {
                    const errorHandlers =
                        requestConfig && !!requestConfig.errorHandlers
                            ? requestConfig.errorHandlers.filter((h) => h.errorCode === error.errorCode)
                            : []

                    let errorHandled = false
                    for (let i = 0; i < errorHandlers.length; i++) {
                        const handler = errorHandlers[i]
                        if (!errorHandled) {
                            errorHandled = await handler.handleError(error.errorToAlert)
                        }
                    }
                    if (!errorHandled) {
                        await dispatch(addModalThunk({ type: "error", errorMessage: error.errorToAlert }))
                    }
                }
            }
            throw error
        }
    }
