import { IApiDoc, IApiDocRoute } from "./../../type/api/apiDocTypes"
import { ActionsUnion } from "../../type/actionsUnion"
import createAction from "../../type/createAction"
import { StoreState } from "../StoreState"

export type ApiState = Readonly<{
    lastUpdated?: Date
    apiDocs?: IApiDoc[]
    routes?: IApiDocRoute[]
    selection?: IApiSelection
}>
const initialState: ApiState = {}

export interface IApiSelection {
    selectedGroup: string
    selectedRoute?: string
    selectedBy: "menu" | "scrolling" | "link"
    disableScrollSelectionUntil?: Date
}

export const apiReducerActions = {
    setApiDocs: (apiDocs: IApiDoc[], apiRoutes: IApiDocRoute[], lastUpdated: Date) =>
        createAction("APP/SET_API_DOCS", { apiDocs, apiRoutes, lastUpdated }),
    setApiSelection: (selection: IApiSelection) => createAction("APP/SET_API_SELECTION", { selection }),
}

export type ApiActions = ActionsUnion<typeof apiReducerActions>

export const apiReducer = (state: ApiState = initialState, action: ApiActions): ApiState => {
    switch (action.type) {
        case "APP/SET_API_DOCS": {
            return {
                ...state,
                apiDocs: action.apiDocs,
                routes: action.apiRoutes,
                lastUpdated: action.lastUpdated,
            }
        }
        case "APP/SET_API_SELECTION": {
            return {
                ...state,
                selection: action.selection,
            }
        }
    }

    return state
}

export const apiSelectors = {
    getApiLastUpdated: (state: StoreState) => state.api.lastUpdated,
    getApiDocs: (state: StoreState) => state.api.apiDocs,
    getApiRoutes: (state: StoreState) => state.api.routes,
    getApiSelection: (state: StoreState) => state.api.selection,
}
