// Does not handle cycles
export function mergeDeep(target: any, ...sources: any[]): any {
    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isArray(target[key]) && isArray(source[key])) {
                target[key] = [...target[key], ...source[key]]
            } else if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }
    if (isArray(target) && isArray(source)) {
        target = [...target, ...source]
    }

    return mergeDeep(target, ...sources)
}

function isObject(item: any) {
    return item && typeof item === "object" && !Array.isArray(item)
}

function isArray(item: any) {
    return item && typeof item === "object" && Array.isArray(item)
}
