import React from "react"
import { mount, route, map, redirect } from "navi"
import { INavigationContext } from "./INavigationContext"
import ManageUserRelationsPage from "../pages/relation/ManageUserRelationsPage"
import ApiHomePage from "../pages/api/ApiHomePage"
import ContentPage from "../pages/public/ContentPage"
import { authService } from "../services/authService"
import { waitForever } from "../util/timeUtil"
import PageAdministrationPage from "../pages/admin/PageAdministrationPage"

export const loggedInOrNotRoutes = mount<INavigationContext>({
    "/": route((request) => {
        return {
            view: <ContentPage path={"/"} />,
        }
    }),
    "/api": route({
        view: <ApiHomePage />,
    }),
    "/manageuserrelations": route({
        view: <ManageUserRelationsPage />,
    }),
    "/pageAdmin": route({
        view: <PageAdministrationPage />,
    }),
    "/:path": route((request) => {
        return {
            view: <ContentPage path={"/" + request.params.path} />,
        }
    }),
    "/:rootPath/:firstLevelPath": route((request) => {
        return {
            view: <ContentPage path={"/" + request.params.rootPath + "/" + request.params.firstLevelPath} />,
        }
    }),
    "/:rootPath/:firstLevelPath/:secondLevelPath": route((request) => {
        return {
            view: (
                <ContentPage
                    path={
                        "/" + request.params.rootPath + "/" + request.params.firstLevelPath + "/" + request.params.secondLevelPath
                    }
                />
            ),
        }
    }),
})

export const mainRoutes = mount<INavigationContext>({
    "*": map<INavigationContext>(async (request, context) => {
        if (request.params.forceauth === "1") {
            const url = request.originalUrl.split("#")[0].replace("forceauth=1", "forceauth=0")
            await authService.login(url)
            return loggedInOrNotRoutes
        }
        if (!authService.getHasApiCookie() && authService.getIsLoggedIn()) {
            if (authService.getIsWaitingForRedirectToAuth()) {
                await waitForever()
            }
            // if (request.url === "/") {
            //     TODO: Redirect to user-portal
            //     return redirect("/login")
            // }
            if (authService.isAuthChecked() || authService.getIsWaitingForLinkToLoginPage()) {
                // authService.setAuthChecked(false)
                // authService.setIsWaitingForLinkToLoginPage(true)
                // TODO: Redirect to user-portal
                // return redirect("/login?returnUrl=" + encodeURIComponent(request.originalUrl), { exact: false })
            } else {
                authService.setAuthChecked(true)
                authService.setIsWaitingForRedirectToAuth(true)
                await authService.login(request.originalUrl.split("#")[0])
            }
        }
        return loggedInOrNotRoutes
    }),
})
