import { hasValue } from "../util/arrayUtil"
import { mergeDeep } from "../util/mergeUtil"
import { IApiDocPathItem, IApiDocRoute, IApiDocSchema, TApiParamIn } from "./../type/api/apiDocTypes"
export function getApiRouteKey(route: IApiDocRoute) {
    return route.overrideExternalMethod || route.method + "_" + route.pathTemplate
}

export interface IExtractedRouteParameter {
    name: string
    schema?: IApiDocSchema
    isRequired: boolean
    in: TApiParamIn
}

export interface IExtractedRouteRequest {
    parameters: IExtractedRouteParameter[]
    example?: any
}

export interface IExtractedRouteResponse {
    code: string
    description?: string
    parameters: IExtractedRouteParameter[]
    example?: any
}

export function extractRouteRequest(doc: IApiDocPathItem): IExtractedRouteRequest {
    const res: IExtractedRouteRequest = {
        parameters: [],
        example: undefined,
    }

    if (doc.parameters) {
        res.example = doc.example
        if (!res.example) {
            const paramExamples = doc.parameters
                .map((p) => [p.name, p.in === "query" ? p.schema?.example : undefined])
                .filter((pair) => pair[1])
            if (paramExamples.length > 0) {
                res.example = paramExamples.reduce((result, pair) => {
                    result[pair[0]] = pair[1]
                    return result
                }, {})
            }
        }
        res.parameters = doc.parameters
            .map((p) => {
                if ((p.schema?.title ?? "").includes("{{ignore}}")) {
                    return undefined
                }
                if (!p.schema?.description && p.description && p.schema) {
                    p.schema.description = p.description
                }
                return {
                    name: p.name,
                    schema: p.schema,
                    isRequired: p.required ?? false,
                    in: p.in,
                }
            })
            .filter(hasValue)
    }
    const body = doc.requestBody?.content?.["application/json"] || doc.requestBody?.content?.["application/*+json"]
    if (body) {
        const props = body.schema.properties ?? {}
        res.parameters = [
            ...res.parameters,
            ...Object.keys(props)
                .map((key) => {
                    if ((props[key]?.title ?? "").includes("{{ignore}}")) {
                        return undefined
                    }
                    const param: IExtractedRouteParameter = {
                        name: key,
                        schema: props[key],
                        isRequired: (body.schema.required ?? []).includes(key),
                        in: "body",
                    }
                    return param
                })
                .filter(hasValue)
                // Filter away props in body that exists in path. Often id from the dto is provided in the path
                .filter((p) => !res.parameters.find((rp) => rp.name === p.name)),
        ]
        const exampleProps = getExampleFromSchema(body.schema)
        res.example = mergeDeep(res.example ?? {}, body.example, exampleProps)
        if (res.example && Object.keys(res.example).length === 0) {
            res.example = undefined
        }
    }
    return res
}

export function extractRouteResponses(doc: IApiDocPathItem): IExtractedRouteResponse[] {
    return Object.keys(doc.responses ?? {}).map((key) => {
        const resp = (doc.responses ?? {})[key]
        let params: IExtractedRouteParameter[] = []
        let exampleProps = undefined
        if (resp.content) {
            const schema = resp.content?.["application/json"]?.schema || resp.content?.["application/*+json"]?.schema
            if (schema) {
                const props = schema.properties ?? {}
                params = Object.keys(props)
                    .map((key) => {
                        if ((props[key]?.title ?? "").includes("{{ignore}}")) {
                            return undefined
                        }
                        const param: IExtractedRouteParameter = {
                            name: key,
                            schema: props[key],
                            isRequired: (schema.required ?? []).includes(key),
                            in: "body",
                        }
                        return param
                    })
                    .filter(hasValue)
                const example = getExampleFromSchema(schema)
                if (Object.keys(example ?? {}).length > 0) {
                    exampleProps = example
                }
            }
        }

        const exampleObj = resp.content?.["application/json"]?.example || resp.content?.["application/*+json"]?.example
        return {
            code: key,
            description: resp.description,
            parameters: params,
            example: exampleProps || exampleObj ? mergeDeep(exampleProps ?? {}, exampleObj) : undefined,
        }
    })
}

function getExampleFromSchema(schema?: IApiDocSchema): { [key: string]: any } | undefined {
    if (!schema) {
        return undefined
    }
    if (schema.type === "object") {
        if (!schema?.properties || Object.keys(schema?.properties).length === 0) {
            return undefined
        }
        const props = schema.properties
        const o = {}
        Object.keys(schema?.properties).forEach((key) => {
            const prop = props[key]
            const propValue = getExampleFromSchema(prop)
            if (typeof propValue !== "undefined") {
                o[key] = propValue
            }
        })
        return Object.keys(o).length === 0 ? undefined : o
    }
    if (schema.type === "array") {
        const arrValue = getExampleFromSchema(schema.items)
        return typeof arrValue === "undefined" ? undefined : [arrValue]
    }

    return schema.example
}

export function getSchemaSubItems(schema?: IApiDocSchema): IExtractedRouteParameter[] {
    if (!schema || (schema.type !== "array" && schema.type !== "object")) {
        return []
    }
    if (schema.items) {
        return getSchemaSubItems(schema.items)
    }
    if (schema.properties) {
        return Object.keys(schema.properties)
            .map((key) => {
                if ((schema.properties?.[key]?.title ?? "").includes("{{ignore}}")) {
                    return undefined
                }
                const prop: IExtractedRouteParameter = {
                    name: key,
                    isRequired: (schema.required ?? []).includes(key),
                    schema: schema.properties?.[key],
                    in: "body",
                }
                return prop
            })
            .filter(hasValue)
    }

    return []
}
