import React, { useState } from "react"
import StandardButton, { IStandardButtonBaseProps } from "./StandardButton"
import SpinnerContainer from "./SpinnerContainer"

interface IStandardButtonWithSpinnerProps extends IStandardButtonBaseProps {
    onClick: (setShowSpinner: TStandardButtonSetShowSpinnerFunc) => Promise<void>
    containerClass?: string
}

export type TStandardButtonSetShowSpinnerFunc = (showSpinner: boolean) => void

const StandardButtonWithSpinner = ({ containerClass, onClick, children, ...otherProps }: IStandardButtonWithSpinnerProps) => {
    const [isLoading, setisLoading] = useState(false)

    return (
        <SpinnerContainer className={containerClass} showSpinner={isLoading}>
            <StandardButton
                {...otherProps}
                onClick={async () => {
                    setisLoading(true)
                    try {
                        await onClick((showSpinner) => setisLoading(showSpinner))
                        setisLoading(false)
                    } catch (err) {
                        setisLoading(false)
                    }
                }}
            >
                {children}
            </StandardButton>
        </SpinnerContainer>
    )
}

export default StandardButtonWithSpinner
