import React, { useEffect, useState } from "react"
import PageContainer from "../../components/page/PageContainer"
import PageWrapper from "../../components/page/PageWrapper"
import { useSelector } from "react-redux"
import { StoreState } from "../../store/StoreState"
import useReduxDispatch from "../../store/useReduxDispatch"
import SpinnerContainer from "../../components/buttons/SpinnerContainer"
import { contentReducerActions, contentSelectors } from "../../store/reducers/contentReducer"
import { addOrUpdateContentGroupThunk, deleteContentGroupThunk, fetchContentGroupsThunk } from "../../store/thunks/contentThunks"
import FloatingLabelsInput from "../../components/form/input/FloatingLabelInput"
import { IContentGroup } from "../../type/doc/IContent"
import StandardButtonWithSpinner from "../../components/buttons/StandardButtonWithSpinner"
import "./PageAdministrationPage.scss"
import { useNavigation } from "react-navi"
import { sortAscending } from "../../util/objectUtil"

interface IPageAdministrationPageProps {}

const PageAdministrationPage = ({}: IPageAdministrationPageProps) => {
    const dispatch = useReduxDispatch()
    const [loading, setLoading] = useState(true)
    const allGroups = useSelector(contentSelectors.getGroups)
    const navigation = useNavigation()
    const groups = Object.values(allGroups ?? [])
    const pages = groups.filter((g) => g.type === "docpage")
    const [locators, setLocators] = useState<{ [itemId: string]: string }>({})

    useEffect(() => {
        setLoading(false)
    }, [allGroups])

    useEffect(() => {
        dispatch(fetchContentGroupsThunk("docpage"))
        setLoading(true)
    }, [])

    const pagesWithNew: IContentGroup[] = [
        {
            itemId: "new",
            locator: "",
            type: "docpage",
        },
        ...sortAscending(pages, (p) => p.locator),
    ]

    return (
        <PageWrapper mediumWidth>
            <PageContainer classNameBody="page-admin-page">
                <SpinnerContainer showSpinner={loading}>
                    {pagesWithNew.map((p) => {
                        return (
                            <div className="page-item" key={p.itemId}>
                                {p.itemId === "new" && <div className="new-page">Add new page:</div>}
                                <div className="locator">
                                    <FloatingLabelsInput
                                        placeholder="Path name of page, e.g. /guides/magento1"
                                        disabled={p.locator === "/"}
                                        value={typeof locators[p.itemId] === "undefined" ? p.locator : locators[p.itemId]}
                                        onChange={(e) => {
                                            setLocators({
                                                ...locators,
                                                [p.itemId]: e.currentTarget.value,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="goto">
                                    {p.itemId !== "new" && (
                                        <StandardButtonWithSpinner
                                            title="Go to"
                                            isSmall
                                            onClick={async () => {
                                                navigation.navigate(p.locator)
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="save">
                                    {p.locator !== "/" && (
                                        <StandardButtonWithSpinner
                                            title="Save"
                                            isSmall
                                            inverted
                                            onClick={async () => {
                                                const locator =
                                                    typeof locators[p.itemId] === "undefined" ? p.locator : locators[p.itemId]
                                                await dispatch(
                                                    addOrUpdateContentGroupThunk(
                                                        "docpage",
                                                        locator,
                                                        p.itemId === "new" ? undefined : p.itemId
                                                    )
                                                )
                                                const locs = {
                                                    ...locators,
                                                }
                                                delete locs.new
                                                setLocators(locs)
                                                await dispatch(fetchContentGroupsThunk("docpage"))
                                            }}
                                        />
                                    )}
                                </div>

                                <div className="delete">
                                    {p.itemId !== "new" && p.locator !== "/" && (
                                        <StandardButtonWithSpinner
                                            title="Delete"
                                            isSmall
                                            isDelete
                                            onClick={async () => {
                                                await dispatch(deleteContentGroupThunk(p.itemId))
                                                await dispatch(fetchContentGroupsThunk("docpage"))
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </SpinnerContainer>
            </PageContainer>
        </PageWrapper>
    )
}

export default PageAdministrationPage
