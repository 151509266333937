import { isDev } from "./util/configUtil"

export interface IClientAppSettings {
    logServerRequests: boolean
    logServerResponses: boolean
    env: string
}

const clientAppSettings: IClientAppSettings = isDev()
    ? require("./clientAppSettingsDevelopment").default
    : require("./clientAppSettingsProduction").default

export const settings = clientAppSettings
