import React, { useState } from "react"
import "./ApiRouteParamGroup.scss"
import { getSchemaSubItems, IExtractedRouteParameter } from "../../services/apiRouteService"
import ApiRouteParam from "./ApiRouteParam"

interface IApiRouteParamGroupProps {
    title: string
    description: string
    params: IExtractedRouteParameter[]
    mustBeOpened?: boolean
    isResponse?: boolean
}

const ApiRouteParamGroup = ({ title, params, description, mustBeOpened, isResponse }: IApiRouteParamGroupProps) => {
    const [isOpen, setIsOpen] = useState(!mustBeOpened)
    const [openParams, setOpenParams] = useState<{ [path: string]: boolean }>({ _default: false })
    const hasSubParams = params.map((p) => getSchemaSubItems(p.schema).length > 0).filter((b) => b).length > 0

    return (
        <div className="api-route-param-group-comp">
            <div className="sub-section parameters">
                <span className="title">{"- " + title + " "}</span>
                {isOpen && hasSubParams && (
                    <span
                        className="toggle-params"
                        onClick={() => {
                            const newValue = !openParams._default
                            const newParams = { ...openParams }
                            Object.keys(newParams).forEach((key) => (newParams[key] = newValue))
                            setOpenParams(newParams)
                        }}
                    >
                        {openParams._default ? "Hide all child parameters" : "Show all child parameters"}
                    </span>
                )}
            </div>
            {description && <div className="description">{description}</div>}
            {params.length > 0 && mustBeOpened && !isOpen && (
                <span
                    className="toggle-open"
                    onClick={() => {
                        setIsOpen(!isOpen)
                    }}
                >
                    {isOpen ? "Hide details" : "Show details"}
                </span>
            )}
            {!isResponse && isOpen && params.length === 0 && <div className="no-params">No parameters</div>}
            {isOpen && params.length > 0 && (
                <div>
                    {params.map((p) => (
                        <ApiRouteParam
                            param={p}
                            key={p.name}
                            path={p.name}
                            openParams={openParams}
                            onSubParamsOpenChange={(path, isOpen) => setOpenParams({ ...openParams, [path]: isOpen })}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ApiRouteParamGroup
