import React from "react"
import { InputProps } from "reactstrap"
import { Input } from "reactstrap"

export interface IDropdownOption {
    text: string
    value: any
}

interface IDropDownListProps extends InputProps {
    options: IDropdownOption[]
}

const DropDownList = ({ options, placeholder, ...otherProps }: IDropDownListProps) => {
    return (
        <Input type="select" {...otherProps}>
            {placeholder && (
                <option key="" value="">
                    --- {placeholder} ---
                </option>
            )}
            {options.map((p) => (
                <option key={p.value} value={p.value}>
                    {p.text}
                </option>
            ))}
        </Input>
    )
}

export default DropDownList
