import React, { useState, useEffect } from "react"
import { Link, useNavigation, useCurrentRoute } from "react-navi"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import "./NavMenu.scss"
import { layoutReducerActions } from "../../store/reducers/layoutReducer"
import { navigationService } from "../../services/navigationService"
import { Route } from "navi"
import cn from "classnames"
import { TUserRelation } from "../../type/TUserRelation"
import { TUserRole } from "../../type/TUserRole"
import { userRelationSelectors } from "./../../store/reducers/userRelationReducer"
import ContentEditorActions from "../content/ContentEditorActions"
import useReduxDispatch from "../../store/useReduxDispatch"

interface INavItemProps extends IMenuLink {
    isActive: boolean
}
const NavItem = ({ isActive, href, iconClass, textId }: INavItemProps) => {
    const { t } = useTranslation()
    const navi = useNavigation()
    const dispatch = useReduxDispatch()
    return (
        <li
            onClick={() => {
                navi.navigate(href)
                dispatch(layoutReducerActions.setNavBarOpen(false))
            }}
            className={cn(isActive && "router-link-exact-active router-link-active")}
        >
            <div className="nav__item">
                {iconClass && <span className={`icon ${iconClass}`}></span>}
                <p className={cn(!iconClass && "no-icon")}>{t(textId)}</p>
            </div>
        </li>
    )
}

interface IMenuLink {
    userRelationTypes?: TUserRelation[]
    isMasterPartner?: boolean
    href: string
    iconClass?: string
    textId: string
    minimumRole?: TUserRole
}

const MenuLinks: IMenuLink[] = [
    {
        userRelationTypes: ["Anonymous", "User", "Merchant", "Partner"],
        href: "/api",
        textId: "menu.api",
    },
    // {
    //     userRelationTypes: ["Anonymous", "User", "Merchant", "Partner"],
    //     href: "/",
    //     textId: "menu.guides",
    // },
    // {
    //     userRelationTypes: ["Anonymous", "User", "Merchant", "Partner"],
    //     href: "/faq",
    //     textId: "menu.faq",
    // },
    {
        userRelationTypes: ["Anonymous", "User", "Merchant", "Partner"],
        href: "/pageAdmin",
        isMasterPartner: true,
        textId: "menu.pageAdmin",
    },
]

const NavMenu = () => {
    const currentUserRelationRole = useSelector(userRelationSelectors.currentUserRelationRole)
    const currentUserRelationType = useSelector(userRelationSelectors.currentUserRelationType)
    const currentRoute = useCurrentRoute()
    const currentPath = currentRoute.url.pathname
    const [path, setPath] = useState(currentRoute.url.pathname)
    const isMasterPartner = useSelector(userRelationSelectors.isMasterPartner)

    useEffect(() => {
        return navigationService.addNavigationListener((value: Route) => {
            // New route, trigger rerender
            if (path !== value.url.pathname) {
                setPath(value.url.pathname)
            }
        })
    }, [])

    const links = MenuLinks.filter(
        (ml) =>
            (!ml.userRelationTypes || ml.userRelationTypes.find((r) => r === currentUserRelationType)) &&
            (!ml.minimumRole || ml.minimumRole <= currentUserRelationRole) &&
            (!ml.isMasterPartner || isMasterPartner)
    )
    return (
        <ul className={cn("sidebar-top")}>
            {links.map((link) => (
                <NavItem isActive={link.href === currentPath} key={link.href} {...link} />
            ))}
            <ContentEditorActions />
        </ul>
    )
}

export default NavMenu
